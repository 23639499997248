:root {
  --bg-dark: #343434;
  --bg-light: #F0F0F0;
  --text-dark: #CCCCCC;
  --text-light: #343434;
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  color: var(--text-light);
  background-color: var(--bg-light);

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

a, button {
  -webkit-tap-highlight-color: transparent;
}